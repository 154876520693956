import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PlanningService} from "../../../../apps/planning-multipractices/planning.service";

@Component({
  selector: 'app-practice-detail',
  templateUrl: './practice-detail.component.html',
  styleUrls: ['./practice-detail.component.scss']
})
export class PracticeDetailComponent implements OnInit {

  public practice: any;
  private practiceReference: string = '';
  defaultAvatarTTSUrl = 'https://cdn.tictacsante.com/assets/images/doc/Default.png';//DEFAULT_PHOTO
  establishmentReference: number = -1;
  fullNumber: string = '';
  images: string[] = [
    "blessure.png",
    "chiropratique.png",
    "physiotherapie.png",
    "therapie-physique.png",
  ];
  showPhoneNumber: boolean = false;
  constructor(private _route: ActivatedRoute,
              private _planningService: PlanningService) { }

  ngOnInit(): void {
    let params = this._route.snapshot.params;
    this.practiceReference = params['practiceRef'];
    this.establishmentReference = params['establishmentRef'];
    this._planningService.getPracticeDetails(this.practiceReference)
      .subscribe((response: any) => {
        this.practice = response.data;
      })
  }

  public getFullAddress(address: string, city: string, country: string, zip_code: string): string {
    let val = "";
    if (address) {
      val = address;
    }
    if (city) {
      if (val) {
        val += ", " + city;
      } else {
        val = city;
      }
    }
    if (country) {
      if (val) {
        val += ", " + country;
      } else {
        val = country;
      }
    }
    if (zip_code) {
      if (val) {
        val += ", " + zip_code;
      } else {
        val = zip_code;
      }
    }
    return val;
  }

  revealPhoneNumber() {
    this.fullNumber = this.practice.phone;
    this.showPhoneNumber = true;
  }
  handleImageError(event: any) {
    event.target.src = this.defaultAvatarTTSUrl;
  }
}
