import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {PlanningService} from './planning.service';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerInputEvent,
  MatDatepickerToggle
} from '@angular/material/datepicker';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators,} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MomentDateModule} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {CommonModule, NgStyle} from '@angular/common';
import {MatIcon} from "@angular/material/icon";
import {MatFormField, MatFormFieldModule, MatSuffix} from "@angular/material/form-field";
import {MatSelect} from "@angular/material/select";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatOption} from "@angular/material/autocomplete";
import {MatButton, MatButtonModule, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatStepper, MatStepperModule} from "@angular/material/stepper";
import {SignInComponent} from "./login/sign-in.component";
import {InscriptionComponent} from "./inscription/inscription.component";
import {MatCard} from "@angular/material/card";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SecurityService} from "./security-service";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-planning',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MomentDateModule,
    MatIcon,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatDatepicker,
    MatDatepickerToggle,
    MatInput,
    MatDatepickerInput,
    NgStyle,
    MatProgressSpinner,
    CommonModule,
    MatSuffix,
    MatIconButton,
    MatTooltip,
    MatMiniFabButton,
    MatRadioGroup,
    MatRadioButton,
    MatButton,
    MatButtonModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    SignInComponent,
    InscriptionComponent,
    MatCard,
  ],
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss', './planning2.component.scss', './authentication.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class PlanningComponent implements OnInit, AfterViewInit {
  establishment: any;
  times_slot: any[] = [];
  filteredSlots: any[] = [];
  establishmentRef: any;
  practiceRef: any;
  patient: any;
  userData: any;
  reasonRef: any;
  practices: any;
  reasons: any;
  selectedTimeSlot: any;
  selectedReason: any;
  selectedPractice: any;
  display_days: number = 5;
  planningForm: FormGroup;
  isToday: boolean = true;
  selected: string = '1';
  spin: boolean = false;
  mobWidth: any;
  availableOnLine: boolean = true;
  allowsNewPatients: boolean = true;
  isMobile: boolean = false;
  nextDateApp: Date | undefined;

  public errorOccurred       : boolean = false;

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: ['', Validators.required],
  });
  fourthFormGroup = this._formBuilder.group({
    fourthCtrl: ['', Validators.required],
  });
  isLinear = true;

  /**
   * Constructor
   *
   * @param {PlanningService} _planningService
   * @param {FormBuilder} _formBuilder
   * @param snackBar
   * @param _securityService
   * @param _route
   * @param {DateAdapter} dateAdapter
   * @param cdr
   */
  constructor(
      private _planningService: PlanningService,
      private _formBuilder: FormBuilder,
      public snackBar: MatSnackBar,
      public _securityService: SecurityService,
      private _route: ActivatedRoute,
      private dateAdapter: DateAdapter<Date>
  ) {
    this.mobWidth = window.screen.width;
    this.dateAdapter.setLocale('fr');
    this.planningForm = this._formBuilder.group(
      {
        motif: [{value:'', disabled: !this.selected}, Validators.required],
        place: ['', Validators.required],
        date_init: [moment()]
      },
      { validator: [] }
    );
  }

  ngAfterViewInit(): void {
    this._planningService.onSelectedTime
      .subscribe(value => {
        if(value){
          this.firstFormGroup.patchValue({
            firstCtrl: "Done"
          });
          this.onClickNextStep1();
          this.goToNextStep();
          this.selectSlot(value.time, value.timeSlot);
        }
      })
    }

  @ViewChild(MatStepper) stepper!: MatStepper;
  @HostListener('window:resize', ['$event.target'])
  onResize(target?: { innerWidth: number; }) {
    if(target){
      if (target.innerWidth <= 270) {
        this.display_days = 1;
        this.isMobile = true;
      } else if (target.innerWidth > 270 && target.innerWidth <= 350) {
        this.display_days = 2;
        this.isMobile = true;
      } else if (target.innerWidth > 350 && target.innerWidth <= 450) {
        this.display_days = 3;
      } else if (target.innerWidth > 450 && target.innerWidth <= 550) {
        this.display_days = 4;
      } else if (target.innerWidth > 550 && target.innerWidth <= 750) {
        this.display_days = 5;
      } else {
        this.display_days = 5;
      }
    }
  }

  ngOnInit(): void {
    // if(!this.dataInit) {
      this._route.paramMap.subscribe((params) => {
        this.establishmentRef = params.get('establishmentRef');  // e.g., 'paris-madeleine'
        if (this.establishmentRef) {
          this.getEstablishmentDetails();
        }else {
          //todo show error message
          return;
        }
      });
      this._route.queryParamMap.subscribe((queryParams) => {
        this.reasonRef = queryParams.get('reason');
        this.practiceRef = queryParams.get('practice');  // e.g., 'paris-madeleine'
        this.load_TimesSlot();
      });
      if (this.mobWidth <= 450) {
        this.display_days = 3;
      }
  }
  getEstablishmentDetails(){
      this._planningService.getEstablishmentDetails(this.establishmentRef)
        .subscribe((res: any) => {
          this.establishment = res?.data;
          this.reasons = res?.data?.reasons;
          this.practices = res?.data?.practices;
          if(this.reasonRef){
            const filterdReasons = this.reasons.filter((e: any)=> e.reference == this.reasonRef)
            if(filterdReasons.length > 0){
              this.selectedReason = filterdReasons[0];
              this.firstFormGroup.patchValue({
                firstCtrl: 'done'
              });
            }
          }else{
            const filterdReasons = this.reasons.filter((e: any)=> e.is_default == true)
            if(filterdReasons.length > 0){
              this.selectedReason = filterdReasons[0];
              this.firstFormGroup.patchValue({
                firstCtrl: 'done'
              });
            }
          }
        })
    }
  load_TimesSlot(): void {
    const params = this.planningForm?.getRawValue();
    params.date_init = (moment(this.planningForm?.getRawValue().date_init)).format('yyyy-MM-DD');

    const paramsTimesSlot = {
      date_init: params.date_init,
      display_days: this.display_days,
      reason_reference: this.selectedReason?.reference || this.reasonRef,
      establishment_reference: this.establishmentRef
    };

    this.spin = true;
    this._planningService.getTimesSlot(paramsTimesSlot).subscribe((res) => {
      this.spin = false;
      if (res.status === 'OK') {
        this.times_slot = res.data.planning;
        let isAvailable = false;
        for (const timesSlot of this.times_slot) {
          if(timesSlot?.times?.length > 0){
            isAvailable = true;
            break;
          }
        }
        this.availableOnLine = isAvailable;
        this.filterSlotsByPracticeSelected();
      }else{
        this.spin = false;
      }
    });
  }
  startProcessRdv(time: any, timeSlot: any): void {
    const dataParams = {
      practice_reference: time.practice_reference,
      start_hour: time.full_hour,
      type: this.selectedReason?.reference || this.reasonRef,
      date: timeSlot.date
    };
    this._planningService.startProcessRdv(dataParams).subscribe((res) => {
      if(res.status === "OK"){
        localStorage.setItem('appointement', JSON.stringify(dataParams));
      }
    });
  }
  connected: boolean = false;
  hidden: boolean = false;
  ischosenDateToday(): any {
    this.isToday = this.planningForm?.controls['date_init'].value.isSame(moment(), 'day');
  }
  nextDays(): void {

    const newDate = this.planningForm?.controls['date_init'].value.add(this.display_days, 'days');
    this.planningForm?.controls['date_init'].setValue(newDate);

    this.spin = false;
    this.load_TimesSlot();
    this.ischosenDateToday();
  }

  previousDays(): void {
    this.ischosenDateToday();

    const newDate = this.planningForm?.controls['date_init'].value.subtract(this.display_days, 'days');
    this.planningForm?.controls['date_init'].setValue(newDate);

    this.load_TimesSlot();
    this.ischosenDateToday();
  }

  step5(motif?: undefined): void {
    this.nextDateApp = undefined;
    if (!this.errorOccurred) {
      this.load_TimesSlot();
    }
  }

  updateCalcs(type: string, event: MatDatepickerInputEvent<Date>) {
    this.nextDateApp = undefined;
    this.ischosenDateToday();
    this.step5();
  }

  checkDate(date: any) {
    return moment(date, "YYYY-MM-DD").isSame(moment(), 'day');
  }

  TimeSlot: any;

  selectSlot(time: any, timeSlot: any) {
    //tood do action
    this.startProcessRdv(time, timeSlot);
    this.secondFormGroup.patchValue({
      secondCtrl: 'Done'
    });
    this.selectedTimeSlot = time;
    this.TimeSlot = timeSlot;
    this._securityService.authenticated()
      .subscribe(value => {
        if (value.status === "OK") {
          this.connected = true;
          this.thirdFormGroup.patchValue({
            thirdCtrl: 'done'
          });
          this.fetchPatientDetails();
          this.goToNextStep();
        } else {
          localStorage.setItem("authenticated", 'false');
          this.goToNextStep();
        }
      })
  }

  selectPractice(practice: any) {
    //todo
    this.selectedPractice = practice;
    this.filterSlotsByPracticeSelected();
  }

  unSelectPractice() {
    this.selectedPractice = null;
    this.filterSlotsByPracticeSelected();
  }

  filterSlotsByPracticeSelected() {
    if(this.practiceRef){
      this.selectedPractice = this.practices.find((e:any)=> e.reference == this.practiceRef);
    }
    if (this.selectedPractice) {
      // Deep copy the array of slots
      let slots = this.times_slot.map(slot => ({
        ...slot,
        times: [...slot.times],  // Clone the times array
      }));

      // Filter times and update count_slots for each slot
      slots.forEach((slot: any) => {
        slot.times = slot.times.filter((st: any) => st.practice_reference === this.selectedPractice.reference);
        slot.count_slots = slot.times.length;  // Update the count_slots property
      });

      this.filteredSlots = slots;  // Assign the deep-copied and filtered slots
    }
    else {
      // Deep copy with times and count_slots when no filtering is needed
      this.filteredSlots = this.times_slot.map(slot => ({
        ...slot,
        times: [...slot.times],
        count_slots: slot.times.length,  // Keep the original count
      }));
    }
  }

  selectReason(reason: any) {
    //todo
    this.selectedReason = reason;
    this.firstFormGroup.patchValue({
      firstCtrl: 'done'
    });
    this.load_TimesSlot();
  }

  onClickNextStep1() {
    if(this.firstFormGroup.valid){
      this.load_TimesSlot();
    }
  }

  goToNextStep() {
    this.stepper.next();
  }

  authenticated() {
    this.fetchPatientDetails();
    this.thirdFormGroup.patchValue({
      thirdCtrl: 'done'
    })
    this.goToNextStep();
  }
  fetchPatientDetails(){
    const userData: any = localStorage.getItem("user_data");
    const patientData: any = localStorage.getItem("patient_data");
    if (typeof userData === "string") {
      this.userData = JSON.parse(userData);
    }
    if (typeof patientData === "string") {
      this.patient = JSON.parse(patientData);
    }
  }

  showPage() {
    this.hidden = !this.hidden;
  }

  onClickConfirmRdv() {
    const _data = {
      patient_reference: this.patient.reference,
    };
    this._planningService.confirmRdv(_data)
      .subscribe(value => {
        if(value.status == "OK"){
          this.fourthFormGroup.patchValue({
            fourthCtrl: 'done'
          })
          this.goToNextStep();
        }else{
          this.snackBar.open('Une erreur s\'est produite', '×', {
            verticalPosition: 'top',
            duration: 5000,
            panelClass: ['red-snackbar'],
          });
        }
      })
  }
}
