// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep google-map {
  width: 100% !important;
  height: 100% !important;
  display: block;
}

.map {
  width: 100% !important;
  display: block !important;
}

.map > div {
  height: var(--map-height, 100vh) !important;
  width: 100% !important;
  position: relative;
  overflow: hidden;
  transition: 0.7s ease;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhlcm8tbWFwLXJlc3VsdC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLHNCQUFBO0VBQ0EsdUJBQUE7RUFDQSxjQUFBO0FBQUY7O0FBR0E7RUFDRSxzQkFBQTtFQUNBLHlCQUFBO0FBQUY7O0FBR0E7RUFDRSwyQ0FBQTtFQUNBLHNCQUFBO0VBQ0Esa0JBQUE7RUFDQSxnQkFBQTtFQUdBLHFCQUFBO0FBQUYiLCJmaWxlIjoiaGVyby1tYXAtcmVzdWx0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXG46Om5nLWRlZXAgZ29vZ2xlLW1hcHtcbiAgd2lkdGg6IDEwMCUgIWltcG9ydGFudDtcbiAgaGVpZ2h0OiAxMDAlICFpbXBvcnRhbnQ7XG4gIGRpc3BsYXk6IGJsb2NrO1xufVxuXG4ubWFwIHtcbiAgd2lkdGg6IDEwMCUgIWltcG9ydGFudDtcbiAgZGlzcGxheTogYmxvY2sgIWltcG9ydGFudDtcbn1cblxuLm1hcCA+IGRpdiB7XG4gIGhlaWdodDogdmFyKC0tbWFwLWhlaWdodCwgMTAwdmgpICFpbXBvcnRhbnQ7XG4gIHdpZHRoOiAxMDAlICFpbXBvcnRhbnQ7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgLW1vei10cmFuc2l0aW9uOiAwLjdzIGVhc2U7XG4gIC13ZWJraXQtdHJhbnNpdGlvbjogMC43cyBlYXNlO1xuICB0cmFuc2l0aW9uOiAwLjdzIGVhc2U7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/pages/home-demo-four/search-engine-result/hero-map-result/hero-map-result.component.scss"],"names":[],"mappings":"AACA;EACE,sBAAA;EACA,uBAAA;EACA,cAAA;AAAF;;AAGA;EACE,sBAAA;EACA,yBAAA;AAAF;;AAGA;EACE,2CAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EAGA,qBAAA;AAAF;AADA,w8BAAw8B","sourcesContent":["\n::ng-deep google-map{\n  width: 100% !important;\n  height: 100% !important;\n  display: block;\n}\n\n.map {\n  width: 100% !important;\n  display: block !important;\n}\n\n.map > div {\n  height: var(--map-height, 100vh) !important;\n  width: 100% !important;\n  position: relative;\n  overflow: hidden;\n  -moz-transition: 0.7s ease;\n  -webkit-transition: 0.7s ease;\n  transition: 0.7s ease;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
