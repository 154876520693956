import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    private _url: string;

    /**
      * constructor
      *
      * @param {HttpClient} _httpClient
      */
    constructor(private _httpClient: HttpClient) {
        this._url = environment.url_api;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([

            ]).then(
                ([]) => {

                    resolve([]);
                },
                reject
            );
        });
    }
    setSession(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getSession(key: string) {
        return JSON.parse(<string>localStorage.getItem(key));
    }

    removeSession(key: string) {
        localStorage.removeItem(key);
    }

}
