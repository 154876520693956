import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {EaHttpAbstract} from "../../../../../../@ea/abstract/http.abstract";

@Injectable({
  providedIn: 'root'
})
export class SearchEngineService {

  paramsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  search = new BehaviorSubject<any>(null);
  practitiensSubject: Subject<any[]> = new Subject<any[]>();
  annuairesSubject: Subject<any[]> = new Subject<any[]>();
  markersFromSearchResultSubject: Subject<any> = new Subject<any>();
  practiciens = [];
  annuaires = [];
  markersFromSearchResult: any;
  dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  citySubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  specialitySubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * constructor
   *
   * @param _eaHttpAbstract
   */
  constructor(private _eaHttpAbstract: EaHttpAbstract) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  emitParams(params: any) {
    this.paramsSubject.next(params);
  }

  emitData(data: any) {
    this.dataSubject.next(data);
  }

  searchCity(paramCity: any) {
    this.citySubject.next(paramCity);
  }

  searchSpeciality(paramSpeciality: any) {
    this.specialitySubject.next(paramSpeciality);
  }

  searchSp(data: any): Observable<any> {
    // delete data.city;//Todo a faire
    if (!data) {
      data = {
        keyword: "",
        city: "",
        country: "",
        speciality_reference: 0
      }
    }
    return this._eaHttpAbstract.post("/public/establishments/search", data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  searchResults(data: any): Observable<any> {
    /**
     * Params
     */
    return this._eaHttpAbstract.post("/public/establishments/search_result", data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
