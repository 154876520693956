import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SeoService} from "../../../../helpers/seo.service";
import {SearchEngineService} from "../search-engine/search-engine.service";

@Component({
  selector: 'search-engine-result',
  templateUrl: './search-engine-result.component.html',
  styleUrls: ['./search-engine-result.component.scss']
})
export class SearchEngineResultComponent implements OnInit {
  items: string[] = ["item1", "item2", "item3", "item4", "item5"];
  info: any;
  country: string | null = null;
  city: string | null = null;
  reason: string | null = null;
  //Filtre distance
  max = 100;
  min = 0;
  value = 0;
  innerHeight!: number;
  public speciality: any;
  speciality_name: string = "";
  currentPosition: any;
  /* constructor */

  constructor(
    private _route: ActivatedRoute,
    private _searchEngineService: SearchEngineService,
    private _seoService: SeoService
  ) {
    let params = this._route.snapshot.params;
    if(localStorage.getItem("firstVisited") !== "index" && localStorage.getItem("firstVisited") !== "detail")
    {
      let dataNetwork = {
        city : params['city'],
        country : params['country']
      };
      if(!localStorage.getItem("currentLocation")){
        localStorage.setItem("chosenLocation", JSON.stringify(dataNetwork));
        localStorage.removeItem("currentLocation");//check if this part is still working
      }
      localStorage.setItem("firstVisited", "result");
    }
    this._searchEngineService.specialitySubject.subscribe((speciality) => {
      if(speciality) {
        this.speciality = speciality;
        this.speciality_name = speciality.type_name;
        let title =
          this.titleCaseWord(this.speciality_name) +
          " à " +
          this.titleCaseWord(params['city']) +
          (params['country'] === "fr" ? ", France" : (params['country'] == 'ma'? ", Maroc" : "")) +
          " Prendre rendez-vous en ligne - TicTacSanté";
        this._seoService.generateTags({
          title: title,
          description: title,
          keywords: `TicTacSanté, Rendez-vous, Médecin, Médecine, ${this.titleCaseWord(
            params['city']
          )}, Santé, ${this.titleCaseWord(speciality.speciality)}`,
        });
      }
    });
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0]?.toUpperCase() + word.substr(1).toLowerCase();
  }

  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
      this.country = params['country'];
      this.city = params['city'];
      this.reason = params['reason'];
    });
    this.innerHeight = window.innerHeight - 108;
  }

  @HostListener("window:scroll") onWindowScroll() {
    const scrollTop = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    let map = document.querySelector(".result-map") as HTMLElement;
    let list = document.querySelector(".list") as HTMLElement;
    let top_toolbar = document.querySelector(".top-toolbar") as HTMLElement;
    let header = document.querySelector(".main-toolbar-fixed") as HTMLElement;
    this.currentPosition = scroll;
    let height = list.offsetHeight;
    // let heroSection = document.querySelector(".hero-section") as HTMLElement;
    let h = 0;
/*    if (scrollTop >= height - 870) {
      //1322
      if (header !== null) {
        h = top_toolbar?.clientHeight;
      }
      map.style.position = "relative";
      map.style.width = "50%";
      // heroSection.style.width = "100%";
      // heroSection.style.marginTop = `${height - 870 + 5}px`;
      // map.style.marginTop = `0px`;
    }
    else {
      map.style.position = "fixed";
      map.style.width = "50%";
      // heroSection.style.width = "39%";
      // if (header == null) heroSection.style.marginTop = `0px`;
      // else heroSection.style.marginTop = `-${top_toolbar.clientHeight}px`;
    }*/
    let heroContainer = document.querySelector(".container-map") as HTMLElement;
    // if (map) {
    //   let calcTop = 109;
    //   if (scrollTop == 0) {
    //     calcTop = 109;
    //   } else if (scrollTop < 34) {
    //     calcTop = calcTop - scrollTop;
    //   } else {
    //     calcTop = 109 - 34;
    //   }
    //   if (scrollTop > 434) {} else {}
    // }
  }
}
