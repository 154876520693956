import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  onLocation: Subject<any> = new Subject<any>();
  private _url: string;
  private _coords!: GeolocationCoordinates;
  public networks : any;
  public Data : any;
  invokeFunction = new EventEmitter();
   /**
     * constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
      this._url = environment.url_api;

    }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
       this.Network_infos()
      ]).then(
        ([net_infos]) => {
           this.networks = net_infos;
           let dataNetwork = {
             city : this.networks.city,
             country : this.networks.country_code.toLowerCase()
           }
           localStorage.setItem('network_data', JSON.stringify(dataNetwork));
          resolve([]);
        },
        reject
      );
    });
  }
  // Get Current Location Coordinates
  setCurrentLocation(doLocation: boolean): any {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this._coords = position.coords;
        if(doLocation) this.setLocation();
        //return position.coords;
      });
    }
    return null;
  }
  setLocation() {
    this.onLocation.next(this._coords);
  }
    /**
   * get ip adress
   *
   * @returns {Promise<any>}
   */
  Network_infos(): Promise<any>{
  var httpParams = new HttpParams()
   return new Promise((resolve, reject) => {
       return resolve({city: "Rabat", "country_code": "ma"});
   });
  }
}
