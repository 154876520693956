import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment/moment";
import {PlanningService} from "../../../../../../apps/planning-multipractices/planning.service";
import {Router} from "@angular/router";

@Component({
  selector: "app-planning-list",
  templateUrl: "./planning-list.component.html",
  styleUrls: ["./planning-list.component.scss", "./planning2.component.scss"],
})
export class PlanningListComponent implements OnInit {
  @Input()
  times_slot!: any[];
  @Input()
  establishment!: any;
  spin: boolean = false;
  @Input() reason!: number;

  constructor(private _planningService: PlanningService,
              private _router: Router,) {}
  ngOnInit(): void {}

  checkDate(date: any) {
    return moment(date, "YYYY-MM-DD").isSame(moment(), 'day');
  }

  selectSlot(time: any, timeSlot: any) {
    this._planningService.onSelectedTime.next({
      time: time,
      timeSlot: timeSlot
    })
    this._router.navigate(['/planning', this.establishment.reference],
      {
        queryParams: { reason: this.reason }
      });
  }
}
