import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {CommonModule} from "@angular/common";
import {environment} from "../../../../environments/environment";
import {SecurityService} from "../security-service";
import {
  DialogPhoneValidationCodeComponent
} from "../inscription/dialog-phone-validation-code/dialog-phone-validation-code.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-auth-signin',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    imports: [
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        CommonModule,
        MatInputModule,
    ],
    standalone: true,
})
export class SignInComponent implements OnInit{
    @Output() nextStep = new EventEmitter<any>();
    @Input() typeAuth= '';
    errorAuthentication = false;
    rememberMe = false;
    public loginForm!: FormGroup;
    public hide = true;
    errorMessage: string = "";
    private idEstablishment!: any;

    constructor(
        private _route: ActivatedRoute,
        public router:Router,
        private _formBuilder: FormBuilder,
        private _securityService :  SecurityService,
        private snackBar: MatSnackBar,
        public _matdialog: MatDialog
    ) {}
    ngOnInit(): void {
        this.initLoginForm();
      this._route.paramMap.subscribe((params) => {
        this.idEstablishment = params.get('establishmentRef');  // e.g., 'paris-madeleine'
      });
    }

    initLoginForm(){
        this.loginForm = this._formBuilder.group({
            username: this._formBuilder.control('',[ Validators.required, Validators.minLength(3)]),
            password : this._formBuilder.control('',[ Validators.required, Validators.minLength(5)]),
            rememberMe : this.rememberMe,
        });
    }

    authentication(){
        const username = this.loginForm.get('username')?.value;
        const password = this.loginForm.get('password')?.value;
        if (this.loginForm.valid) {
            this._securityService.authentication(username, password, this.idEstablishment)
                .subscribe((response) => {
                    if (response.status === "OK") {
                      if(response?.data?.verify_sms === true){
                        this.verifySms();
                      }else{
                        this.errorAuthentication = false;
                        if(this.typeAuth == 'booking'){
                          this.nextStep.emit();
                        }else{
                          this.router.navigate(['/']);
                        }
                      }
                    } else {
                      if(response?.message?.error_auth_patient) {
                        this.errorMessage = "La connexion a échoué";
                        this.snackBar.open(this.errorMessage, '×', {
                          verticalPosition: 'top',
                          duration: 5000,
                          panelClass: ['red-snackbar'],
                        });
                      }else if(response?.message?.error_disabled_account)
                      {
                          this.errorMessage = "Votre compte est désactivé";
                          this.snackBar.open(this.errorMessage, '×', {
                              verticalPosition: 'top',
                              duration: 5000,
                              panelClass: ['red-snackbar'],
                          });
                      }else {
                        this.errorMessage = "Nom d'utilisateur ou mot de passe invalides.";
                        this.snackBar.open(this.errorMessage, '×', {
                            verticalPosition: 'top',
                            duration: 5000,
                            panelClass: ['red-snackbar'],
                        });
                        }
                    }
                })
        }
    }
    protected readonly environment = environment;

  private verifySms() {
    const dialogRef = this._matdialog.open(DialogPhoneValidationCodeComponent, {
      disableClose: false,
      data: {
        title: 'Validation',
      },
      panelClass:"bg-white"
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        if(result.validCode){
          const successMessage = "Votre GSM est vérifié avec succès";
          this.snackBar.open(successMessage, '×', {
            verticalPosition: 'top',
            duration: 5000,
            panelClass: ['green-snackbar'],
          });
          this.errorAuthentication = false;
          if(this.typeAuth == 'booking'){
            this.nextStep.emit();
          }else{
            this.router.navigate(['/']);
          }
        }
      }
    });
  }
}
