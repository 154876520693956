import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {SecurityService} from "../../security-service";

@Component({
  selector: 'app-dialog-phone-validation-code',
  templateUrl: './dialog-phone-validation-code.component.html',
  styleUrls: ['./dialog-phone-validation-code.component.css'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIcon,
    ReactiveFormsModule,
    MatInput,
    MatButton,
    CommonModule
  ],
})
export class DialogPhoneValidationCodeComponent implements OnInit {
  validateForm!: FormGroup;
  isLoading: boolean = false;
  errorMessage: string = "";
  constructor(
    private DialogRef: MatDialogRef<DialogPhoneValidationCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    public _securityService: SecurityService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.validateForm = this._formBuilder.group({
      code: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
    });
  }
  valider() {
    this.isLoading = true;
    if (this.validateForm.valid) {
      this._securityService.validateInscription(this.validateForm.value).subscribe((res) => {
        if(res.status === "ERROR"){
          this.errorMessage = "Code incorrect";
          this.snackBar.open(this.errorMessage, '×', {
            verticalPosition: 'top',
            duration: 5000,
            panelClass: ['red-snackbar'],
          });
        }else if(res.status === "OK"){
          this.data.validCode = true;
          this.DialogRef.close(this.data);
        }
        this.isLoading = false;
      });
    }
  }
}
