import {Injectable} from "@angular/core";
import {EaHttpAbstract} from "../../../../../@ea/abstract/http.abstract";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class ContactPageService {
  constructor(private _eaHttpAbstract: EaHttpAbstract) { }

  public saveContactUs(data: any): Observable<any> {
    data.source = 'multi-practices';
    return this._eaHttpAbstract.post("/establishments/save_contact", data);
  }
}
