import {
  Component,
  ElementRef, EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit, Output,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Settings} from "../../../../helpers/autocomplete/auto-complete.component";
import {AutoCompleteSearchService} from "../../../../helpers/autocomplete/auto-complete.service";
import {SearchEngineService} from "./search-engine.service";
import {LocationService} from "../../../../helpers/location.service";
import {UtilsService} from "../../../../helpers/utils.service";

@Component({
    selector: 'app-search-engine',
    templateUrl: './search-engine.component.html',
    styleUrls: ['./search-engine.component.scss'],
})
export class SearchEngineComponent implements OnInit {
  formSearch!: FormGroup;
  public net_infos: any;
  public componentData: any = "";
  arrow: string = "keyboard_arrow_down";
  specialityControl = new FormControl();
  filteredSpecialities!: any[];
  filteredPractices: any[] = [];
  isShowAutocomplete: boolean = false;
  specialites: any[] = [];
  practiciens: any[] = [];
  specialityLibelleSelected = null;
  specialitySelected!: any;
  data: any;
  urlParams: any;
  city = "";
  country = "";
  country_long_name = "";
  parisLocation =
    {
      description: "Paris, France",
      matched_substrings: [
        {
          length: 5,
          offset: 0,
        },
      ],
      place_id: "ChIJD7fiBh9u5kcRYJSMaMOCCwQ",
      reference: "ChIJD7fiBh9u5kcRYJSMaMOCCwQ",
      structured_formatting: {
        main_text: "Paris",
        main_text_matched_substrings: [
          {
            length: 5,
            offset: 0,
          },
        ],
        secondary_text: "France",
      },
      terms: [
        {
          offset: 0,
          value: "Paris",
        },
        {
          offset: 7,
          value: "France",
        },
      ],
      types: ["locality", "political", "geocode"],
    };
  rabatLocation =
    {
      description: "Rabat, Maroc",
      matched_substrings: [
        {
          length: 1,
          offset: 0,
        },
      ],
      place_id: "ChIJwcVQH4drpw0RawcIdO1GyXo",
      reference: "ChIJwcVQH4drpw0RawcIdO1GyXo",
      structured_formatting: {
        main_text: "Rabat",
        main_text_matched_substrings: [
          {
            length: 1,
            offset: 0,
          },
        ],
        secondary_text: "Maroc",
      },
      terms: [
        {
          offset: 0,
          value: "Rabat",
        },
        {
          offset: 7,
          value: "Maroc",
        },
      ],
      types: ["locality", "political", "geocode"],
    };
  defaultAvatarTTSUrl = 'https://cdn.tictacsante.com/assets/images/doc/Default.png';//DEFAULT_PHOTO
  @Input() userSettings!: Settings;
  @Output()
  componentCallback: EventEmitter<any> = new EventEmitter<any>();
  private recentSearchData: any = [];
  public locationInput: string = "";
  public gettingCurrentLocationFlag: boolean = false;
  public dropdownOpen: boolean = false;
  public recentDropdownOpen: boolean = false;
  public queryItems: any = [];
  public isSettingsError: boolean = false;
  public settingsErrorMsg: string = "";
  public settings: Settings = {};
  private selectedDataIndex: number = -1;
  private userSelectedOption: any = "";
  private defaultSettings: Settings = {
    geoPredictionServerUrl: "",
    geoLatLangServiceUrl: "",
    geoLocDetailServerUrl: "",
    geoCountryRestriction: ["fr", "ma"],
    geoTypes: ["(regions)"],
    geoLocation: [],
    geoRadius: 0,
    serverResponseListHierarchy: [],
    serverResponseatLangHierarchy: [],
    serverResponseDetailHierarchy: [],
    resOnSearchButtonClickOnly: false,
    useGoogleGeoApi: true, //true
    inputPlaceholderText: "Où ? (ville, Quartier…)",
    inputString: "",
    showSearchButton: false,
    showRecentSearch: false, //true
    showCurrentLocation: false, //true
    recentStorageName: "Recherches récentes",
    noOfRecentSearchSave: 5,
    currentLocIconUrl: "",
    searchIconUrl: "",
    locationIconUrl: "",
  };

  @Input() bar_style!: string;
  @Input("speciality") speciality: any;
  @HostBinding("class") classsreach = "";
  @HostListener("document:click")
  clickout() {
    this.isShowAutocomplete = false;
    this.dropdownOpen = false;
  }

  responsiveInput(width: number) {
    let mr = document.querySelector(".moteur-de-recherhe") as HTMLElement;
    let home_search = document.querySelector(".home_search") as HTMLElement;
    if(home_search !== null){
      if(width < 810){
        mr.style.width = `${(width - 10)}px`
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let width = event.target.innerWidth;
    this.responsiveInput(width)
  }
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _elmRef: ElementRef,
    private _autoCompleteSearchService: AutoCompleteSearchService,
    private _formBuilder: FormBuilder,
    private _searchEngineService: SearchEngineService,
    private _route: ActivatedRoute,
    private _router: Router,
    public _locationService: LocationService,
    public _utilsService: UtilsService
  ) {
    this.urlParams = this._route.snapshot.paramMap;
  }
  ngOnInit() {//Todo fix this

    // ToDo : Rectification en fonctionne de la localisation si différent
    const network: any = { };
    let data = JSON.parse(<string>localStorage.getItem('network_data'));
    if(data){
      network.keyword = "";
      network.city = data?.city;
      network.country = data?.country;
      data.keyword = "";
      data.city = "";
      data.country = "";
    }
    this._searchEngineService.searchSp(data)
      .subscribe();
    this.responsiveInput(window.innerWidth);
    this.formSearch = this._formBuilder.group({
      location: [""],
      speciality: [""],
    });
    this.getLists();
    this.moduleInit();
  }

  checkCountry(net: any) :any{
    if(net?.country?.toString().toLowerCase() == 'fr'){
      return this.parisLocation;
    }else {
      return this.rabatLocation;
    }
  }

  changeMapPositon(param: any){
    this._autoCompleteSearchService
      .getGeoPlaceDetail(param?.place_id)
      .subscribe((data: any) => {
        if (data) {
          this.setRecentLocation(data);
          this.formSearch.controls["location"].setValue('');
        }
      });
  }

  getLists() {
    const Data: any = {};
    Data.keyword = "";
    if (this.urlParams.params.country && this.urlParams.params.city) {
      Data.city = this.urlParams.params.city.toLowerCase();
      Data.country = this.urlParams.params.country.toLowerCase();
    } else {
      const network = localStorage.getItem("network_data");
      if (typeof network === "string") {
        this.net_infos = JSON.parse(network);
      }
      let param = null;
      if(localStorage.getItem("chosenLocation") || localStorage.getItem("currentLocation")){
        let netInfo = '';
        if(localStorage.getItem("chosenLocation")){
          netInfo = <string>localStorage.getItem("chosenLocation");
        }else if (localStorage.getItem("currentLocation")){
          netInfo = <string>localStorage.getItem("currentLocation");
        }
        this.net_infos = netInfo;
        param = this.checkCountry(this.net_infos);
        if(this.net_infos?.place_id){
          this.changeMapPositon(this.net_infos);
        }else{
          this.changeMapPositon(param);
        }
        // this.getPlaceLocationInfo(param);
      } else {
        if(localStorage.getItem("network_data")){
          this.net_infos = JSON.parse(<string>localStorage.getItem("network_data"));
          param = this.checkCountry(this.net_infos);
          this.changeMapPositon(param);
        }
      }
      Data.city = this.city?.toLowerCase()
      Data.country = this.net_infos?.country?.toLowerCase()
    }
    this._searchEngineService.search.subscribe((r) => {

      if(r){
        this.practiciens = r?.list_practices;
        this.specialites = r?.list_specialties;
        //
        if(this.specialites)
          this.specialites.forEach((speciality) => {
            let speciality_URL = this.urlParams.params.speciality;
            if (speciality_URL == speciality.url) {
              this.specialitySelected = speciality;
              this.selectSpeciality(speciality);
            }
          });

        if (this.urlParams.params?.country !== undefined &&
          this.urlParams.params.country !== 'undefined' && this.urlParams.params?.city !== undefined && this.urlParams.params.city !== 'undefined') {
          this.formSearch.controls["location"].setValue(
            this.titleCaseWord(this.urlParams.params.city) + (this.urlParams?.params?.city? ", " : "") +  (this.urlParams.params.country === "fr" ? "France" : "Maroc")
          );
        }
      }
    });
  }

  selectSpeciality(item: any) {
    this.specialityLibelleSelected = item.type_name;
    this.specialitySelected = item;
    this.specialityControl.setValue(item.type_name);
    this.isShowAutocomplete = false;
    this._autoCompleteSearchService.changeSpeciality(item);
    this._searchEngineService.searchSpeciality(item);
  }

  search() {
    let params: any;
    if(this.specialitySelected){
      if(this.city.length === 0) {
        params = {
          reason: this.specialitySelected,
          city: this.urlParams?.params?.city?.toLowerCase(),
          country: this.urlParams?.params?.country?.toLowerCase(),
          page: 0,
          country_long_name: this.country_long_name,
        };
        localStorage.setItem("searchHistory", JSON.stringify(params));
        this._searchEngineService.emitParams(params);
        this._searchEngineService.emitData(params);
      } else {
        params = {
          reason: this.specialitySelected,
          city: this.city?.toLowerCase(),
          country: this.country?.toLowerCase(),
          page: 1,
          country_long_name: this.country_long_name,
        };
        this._searchEngineService.emitParams(params);
        this._searchEngineService.emitData(this.data);
      }
    }else{
      if(this.city.length === 0) {
        params = {
          // reason: this.specialitySelected,
          city: this.urlParams?.params?.city?.toLowerCase(),
          country: this.urlParams?.params?.country?.toLowerCase(),
          page: 0,
          country_long_name: this.country_long_name,
        };
        localStorage.setItem("searchHistory", JSON.stringify(params));
        this._searchEngineService.emitParams(params);
        this._searchEngineService.emitData(params);
      } else{
        params = {
          // reason: this.specialitySelected,
          city: this.city?.toLowerCase(),
          country: this.country?.toLowerCase(),
          page: 1,
          country_long_name: this.country_long_name,
        };
        this._searchEngineService.emitParams(params);
        this._searchEngineService.emitData(this.data);
      }
    }
    this._router.navigate(['/search'], {
      queryParams: {
        country: params.country,
        city: params.city,
        // reason: params.reason.reference
      }
    });
  }

  click() {
    this._locationService.setCurrentLocation(true);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    // this.subscription.unsubscribe();
  }

  ngOnChanges(change: SimpleChanges) {
    if(change["bar_style"]?.currentValue)
      this.classsreach = change["bar_style"].currentValue;
    if (change["speciality"]?.currentValue) {
      this.selectSpeciality(change["speciality"].currentValue);
    }
  }

  keyWordSearch: string = '';
  clickSearchSpeciality(event: any): void {
    this.keyupSearchSpeciality(event)
  }

  showSearchSpeciality(){
    this.keyupSearchSpeciality(null)
  }

  keyupSearchSpeciality(event: any): void {
    let keyWord = event?.target?.value;
    if(keyWord) {
      this.keyWordSearch = keyWord;
      const data = {
        city: this.city.toLowerCase(),
        country: this.country.toLowerCase(),
        keyword: this.keyWordSearch.toLowerCase()
      };
      this._searchEngineService.searchSp(data).subscribe((response: any) => {
        this.filteredSpecialities = response?.data?.reasons;
        this.filteredPractices = response?.data?.establishments;
      });
      setTimeout(()=>{
        this.isShowAutocomplete = !(this.filteredSpecialities?.length == 0 && this.filteredPractices?.length == 0);
      },500);
    }else{
      this.filteredSpecialities = [];
      this.filteredPractices = [];
      this.isShowAutocomplete = false;
    }
  }

  changeSpeciality(event: any) {
    if (event.target.value.length === 0) {
      let paramSpeciality = {
        url: "",
      };
      this._searchEngineService.searchSpeciality(paramSpeciality);
    }
  }

  changeLocation(event: any) {
    if (event.target.value.length === 0) {
      this.city = "";
      // this.getLists();
    }
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  changeCountry(Data: any){
    this._searchEngineService.searchSp(Data).subscribe((r) => {
      this.practiciens = r.data.list_practices;
      this.specialites = r.data.list_specialties;
      //
      this.specialites.forEach((speciality) => {
        let speciality_URL = this.urlParams.params.speciality;
        if (speciality_URL == speciality.url) {
          this.specialitySelected = speciality;
          this.selectSpeciality(speciality);
        }
      });

      if (this.urlParams.params?.country !== undefined &&
        this.urlParams.params.country !== 'undefined' && this.urlParams.params?.city !== undefined && this.urlParams.params.city !== 'undefined') {
        this.formSearch.controls["location"].setValue(
          this.titleCaseWord(this.urlParams.params.city) + (this.urlParams?.params?.city? ", " : "") +  (this.urlParams.params.country === "fr" ? "France" : "Maroc")
        );
      }
    });
  }

  //function called when click event happens in input box. (Binded with view)
  clickSearchLocation(event: any): any {
    event.target.select();
    this.keyupSearchLocation(event);
  }

  //function called when there is a change in input. (Binded with view)
  keyupSearchLocation(event: any): any {
    //console.log('inputString => ',this.settings.inputString);
    //this.locationInput = this.formSearch.get('location').value;
    let inputVal: any = this.formSearch.get("location")?.value;

    this._searchEngineService.emitData(this.formSearch.controls["location"].value);
    //let inputVal: any = this.locationInput;
    // console.log(inputVal);
    if (event.keyCode === 40 || event.keyCode === 38 || event.keyCode === 13) {
      this.navigateInList(event.keyCode);
    } else if (inputVal) {
      this.getListQuery(inputVal);
    } else {
      this.queryItems = [];
      if (this.userSelectedOption) {
        this.userQuerySubmit("false");
      }
      this.userSelectedOption = "";
      if (this.settings.showRecentSearch) {
        this.showRecentSearch();
      } else {
        this.dropdownOpen = false;

      }
    }
    //console.log(this.queryItems);
  }

  //function to execute when user hover over autocomplete list.(binded with view)
  activeListNode(index: number): any {
    for (let i: number = 0; i < this.queryItems.length; i++) {
      if (index === i) {
        this.queryItems[i].active = true;
        this.selectedDataIndex = index;
      } else {
        this.queryItems[i].active = false;
      }
    }
  }

  //function to execute when user select the autocomplete list.(binded with view)
  selectedListNode(index: number): any {
    // console.log('selected city')
    // console.log(index);
    this.dropdownOpen = false;
    // console.log(this.recentDropdownOpen);
    if (this.recentDropdownOpen) {
      this.setRecentLocation(this.queryItems[index]);
    } else {
      this.getPlaceLocationInfo(this.queryItems[index]);
    }
  }

  //function to close the autocomplete list when clicked outside. (binded with view)
  closeAutocomplete(event: any): any {
    if (!this._elmRef.nativeElement.contains(event.target)) {
      this.selectedDataIndex = -1;
      this.dropdownOpen = false;
    }
  }

  //function to manually trigger the callback to parent component when clicked search button.
  userQuerySubmit(selectedOption?: any): any {
    let _userOption: any =
      selectedOption === "false" ? "" : this.userSelectedOption;
    if (_userOption) {
      this.componentCallback.emit({
        response: true,
        data: this.userSelectedOption,
      });
    } else {
      this.componentCallback.emit({ response: false, reason: "No user input" });
    }
  }

  //function to get user current location from the device.
  /*currentLocationSelected(): any {
    if (isPlatformBrowser(this.platformId)) {
      this.gettingCurrentLocationFlag = true;
      this.dropdownOpen = false;
      this._autoCompleteSearchService.getGeoCurrentLocation().then((result: any) => {
        if (!result) {
          this.gettingCurrentLocationFlag = false;
          this.componentCallback.emit({'response': false, 'reason': 'Failed to get geo location'});
        } else {
          this.getCurrentLocationInfo(result);
        }
      });
    }
  }*/

  //module initialization happens. function called by ngOninit and ngOnChange private
  private moduleInit(): any {
    //console.log("init");
    this.settings = this.setUserSettings();
    // console.log(this.settings);
    //condition to check if Radius is set without location detail.
    if (this.settings.geoRadius) {
      if (this.settings.geoLocation.length !== 2) {
        this.isSettingsError = true;
        this.settingsErrorMsg =
          this.settingsErrorMsg +
          'Radius should be used with GeoLocation. Please use "geoLocation" key to set lat and lng. ';
      }
    }

    //condition to check if lat and lng is set and radious is not set then it will set to 20,000KM by default
    if (this.settings.geoLocation.length === 2 && !this.settings.geoRadius) {
      this.settings.geoRadius = 20000000;
    }
    if (this.settings.showRecentSearch) {
      this.getRecentLocations();
    }
    if (!this.settings.useGoogleGeoApi) {
      if (!this.settings.geoPredictionServerUrl) {
        this.isSettingsError = true;
        this.settingsErrorMsg =
          this.settingsErrorMsg +
          'Prediction custom server url is not defined. Please use "geoPredictionServerUrl" key to set. ';
      }
      if (!this.settings.geoLatLangServiceUrl) {
        this.isSettingsError = true;
        this.settingsErrorMsg =
          this.settingsErrorMsg +
          'Latitude and longitude custom server url is not defined. Please use "geoLatLangServiceUrl" key to set. ';
      }
      if (!this.settings.geoLocDetailServerUrl) {
        this.isSettingsError = true;
        this.settingsErrorMsg =
          this.settingsErrorMsg +
          'Location detail custom server url is not defined. Please use "geoLocDetailServerUrl" key to set. ';
      }
    }
    this.formSearch.controls["location"].setValue(this.settings.inputString);
    //this.locationInput = this.settings.inputString;
  }

  //function to process the search query when pressed enter.
  private processSearchQuery(): any {
    if (this.queryItems.length) {
      if (this.selectedDataIndex > -1) {
        this.selectedListNode(this.selectedDataIndex);
      } else {
        this.selectedListNode(0);
      }
    }
  }

  //function to set user settings if it is available.
  private setUserSettings(): Settings {
    // let _tempObj: any = {}; todo
    // if (this.userSettings && typeof this.userSettings === "object") {
    //   let keys: string[] = Object.keys(this.defaultSettings);
    //   for (let value of keys) {
    //     _tempObj[value] =
    //       this.userSettings[value] !== undefined
    //         ? this.userSettings[value]
    //         : this.defaultSettings[value];
    //   }
    //   return _tempObj;
    // } else {
    // }
    return this.defaultSettings;
  }

  //function to get the autocomplete list based on user input.
  private getListQuery(value: string): any {
    this.recentDropdownOpen = false;
    if (this.settings.useGoogleGeoApi) {
      let _tempParams: any = {
        query: value,
        countryRestriction: this.settings.geoCountryRestriction,
        geoTypes: this.settings.geoTypes,
      };
      if (this.settings.geoLocation.length === 2) {
        _tempParams.geoLocation = this.settings.geoLocation;
        _tempParams.radius = this.settings.geoRadius;
      }
      this._autoCompleteSearchService
        .getGeoPrediction(_tempParams)
        .subscribe((result: any) => {
          this.updateListItem(result);
        });
    } else {
      this._autoCompleteSearchService
        .getPredictions(this.settings.geoPredictionServerUrl, value)
        .then((result: any) => {
          result = this.extractServerList(
            this.settings.serverResponseListHierarchy,
            result
          );
          this.updateListItem(result);
        });
    }
  }

  //function to extratc custom data which is send by the server.
  private extractServerList(arrayList: any, data: any): any {
    if (arrayList.length) {
      let _tempData: any = data;
      for (let key of arrayList) {
        _tempData = _tempData[key];
      }
      return _tempData;
    } else {
      return data;
    }
  }

  //function to update the predicted list.
  private updateListItem(listData: any): any {
    this.queryItems = listData ? listData : [];
    this.dropdownOpen = true;
  }

  //function to show the recent search result.
  private showRecentSearch(): any {
    this.recentDropdownOpen = true;
    this.dropdownOpen = true;
    this._autoCompleteSearchService
      .getRecentList(this.settings.recentStorageName)
      .then((result: any) => {
        if (result) {
          this.queryItems = result;
        } else {
          this.queryItems = [];
        }
      });
  }

  //function to navigate through list when up and down keyboard key is pressed;
  private navigateInList(keyCode: number): any {
    let arrayIndex: number = 0;
    //arrow down
    if (keyCode === 40) {
      if (this.selectedDataIndex >= 0) {
        arrayIndex =
          this.selectedDataIndex + 1 <= this.queryItems.length - 1
            ? this.selectedDataIndex + 1
            : 0;
      }
      this.activeListNode(arrayIndex);
    } else if (keyCode === 38) {
      //arrow up
      if (this.selectedDataIndex >= 0) {
        arrayIndex =
          this.selectedDataIndex - 1 >= 0
            ? this.selectedDataIndex - 1
            : this.queryItems.length - 1;
      } else {
        arrayIndex = this.queryItems.length - 1;
      }
      this.activeListNode(arrayIndex);
    } else {
      this.processSearchQuery();
    }
  }

  //function to execute to get location detail based on latitude and longitude.
  private getCurrentLocationInfo(latlng: any): any {
    if (this.settings.useGoogleGeoApi) {
      this._autoCompleteSearchService
        .getGeoLatLngDetail(latlng)
        .subscribe((result: any) => {
          if (result) {
            this.setRecentLocation(result);
          }
          this.gettingCurrentLocationFlag = false;
        });
    } else {
      this._autoCompleteSearchService
        .getLatLngDetail(
          this.settings.geoLatLangServiceUrl,
          latlng.lat,
          latlng.lng
        )
        .then((result: any) => {
          if (result) {
            result = this.extractServerList(
              this.settings.serverResponseatLangHierarchy,
              result
            );
            this.setRecentLocation(result);
          }
          this.gettingCurrentLocationFlag = false;
        });
    }
  }

  //function to retrive the location info based on goovle place id.
  private getPlaceLocationInfo(selectedData: any): any {
    this.formSearch.setValue({
      location: selectedData.description,
      speciality: "",
    });
    if (this.settings.useGoogleGeoApi) {
      this._autoCompleteSearchService
        .getGeoPlaceDetail(selectedData.place_id)
        .subscribe((data: any) => {
          if (data) {
            this.setRecentLocation(data);
          }
        });
    } else {
      this._autoCompleteSearchService
        .getPlaceDetails(
          this.settings.geoLocDetailServerUrl,
          selectedData.place_id
        )
        .then((result: any) => {
          if (result) {
            result = this.extractServerList(
              this.settings.serverResponseDetailHierarchy,
              result
            );
            this.setRecentLocation(result);
          }
        });
    }
  }

  //function to store the selected user search in the localstorage.
  private setRecentLocation(data: any): any {
    data = JSON.parse(JSON.stringify(data));
    data.description = data.description
      ? data.description
      : data.formatted_address;
    data.active = false;
    this.selectedDataIndex = -1;
    //console.log(data);
    this.getLocationInfos(data);
    this._autoCompleteSearchService.changeLocation(data);
    // this.locationInput = data.description;
    this.formSearch.controls["location"].setValue(data.description);
    // console.log(this.locationInput);
    if (this.settings.showRecentSearch) {
      this._autoCompleteSearchService.addRecentList(
        this.settings.recentStorageName,
        data,
        this.settings.noOfRecentSearchSave
      );
      this.getRecentLocations();
    }
    this.userSelectedOption = data;
    //below code will execute only when user press enter or select any option selection and it emit a callback to the parent component.
    if (!this.settings.resOnSearchButtonClickOnly) {
      this.componentCallback.emit({ response: true, data: data });
    }
  }

  //function to retrive the stored recent user search from the localstorage.
  private getLocationInfos(data: any): any {
    //console.log(data);
    this.componentData = this._utilsService.setSession(
      "history recent",
      data.description
    );
    this.city = "";
    this.country = "";
    data.address_components.forEach((res: any) => {
      if (res.types[0] === "locality") this.city = res.short_name;
      if (res.types[0] === "country") {
        this.country = res.short_name;
        this.country_long_name = res.long_name;
      }
    });

    //console.log('data ', data);
    this.data = {
      location: data.geometry.location,
    };
    this._searchEngineService.searchCity(data);
    // this.getLists();
    // console.log('city ', this.city);
    //console.log('country ', this.country);
    //console.log('country_long_name',this.country_long_name);
  }

  //function to retrive the stored recent user search from the localstorage.
  private getRecentLocations(): any {
    this._autoCompleteSearchService
      .getRecentList(this.settings.recentStorageName)
      .then((data: any) => {
        this.recentSearchData = data && data.length ? data : [];
      });
  }
  handleImageError(event: any) {
    event.target.src = this.defaultAvatarTTSUrl;
  }
  // imageUtils = new ImageUtils();
}
