import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import {EaHttpAbstract} from "../../../../@ea/abstract/http.abstract";

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor(
    private _eaHttpAbstract: EaHttpAbstract
  ) {}
  /**
   * sign-in
   *
   * @returns Promise<any>
   **/
  authentication(login: string, password: string, establishment_reference: number): Observable<any> {
    const data = {
      login,
      password,
      type:'multipractices',
      establishment_reference:establishment_reference,
    }
    return this._eaHttpAbstract.post("/security/auth/sign_in", data)
      .pipe(
        map((response: any)=>{
          if(response.status == 'OK'){
            if(response?.data?.verify_sms === true){
              localStorage.setItem("security_token", response?.data?.security_token)
            }else {
              localStorage.setItem("user_data", JSON.stringify(response.data.data_user));
              localStorage.setItem("patient_data", JSON.stringify(response.data.data_patient));
            }
          }
          return response;
        })
      );
  }
  /**
   * user connected
   *
   * @returns Promise<any>
   **/
  authenticated(): Observable<any> {
    return this._eaHttpAbstract.post("/security/auth/authenticated", {})
      .pipe(
        map((response: any)=>{
          if (response.status === "OK") {
            localStorage.setItem("authenticated", 'true');
          }
          else {
            localStorage.setItem("authenticated", 'false');
          }
          return response;
        })
      );
  }
  initInscription(data: any): Observable<any> {
    const _data:any = {
      civility: data.genre,
      first_name: data.firstname,
      last_name: data.lastname,
      phone: data.phone,
      gsm: data.mobile,
      country: "Maroc",//todo
      iso2: "ma",//todo
      prefix: data.prefix,//todo
      email: data.email,
      password: data.password,
      source: 'multipractices',
      establishment_reference: data.establishment_reference,
    };
    return this._eaHttpAbstract.post("/security/register_new_patient", _data).pipe(
      map((response: any)=>{
        if(response.status === "OK"){
          _data.reference = response?.data?.reference;
          localStorage.setItem("patient_data", JSON.stringify(_data));
          localStorage.setItem("security_token", response?.data?.security_token)
        }
        return response;
      })
    );
  }

  /**
   * Retreive Person Data
   * * @param validationcode
   * *@param data: Array
   * * @returns {Promise<any>}
   */
  validateInscription(data: any): Observable<any> {
    const _data = {
      validation_code: data.code,
      security_token: localStorage.getItem("security_token"),
    }
    return this._eaHttpAbstract.post("/security/validate_inscription", _data)
      .pipe(
        map((response: any)=> {
          if(response.status === "OK"){
            localStorage.setItem("user_data", JSON.stringify(response?.data?.data_user));
            localStorage.setItem("patient_data", JSON.stringify(response?.data?.data_patient));
          }
          return response;
        })
      );
  }
}
