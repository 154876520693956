import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PlanningService} from "../../../apps/planning-multipractices/planning.service";

@Component({
  selector: 'app-team-page',
  templateUrl: './team-page.component.html',
  styleUrls: ['./team-page.component.scss']
})
export class TeamPageComponent implements OnInit {
  establishmentReference!: number;
  establishment!: any;

  constructor(private _route: ActivatedRoute,
              private _planningService: PlanningService,) { }

  ngOnInit(): void {
    let params = this._route.snapshot.params;
    this.establishmentReference = params['establishmentRef'];
    this._planningService.getEstablishmentDetails(this.establishmentReference)
      .subscribe((response: any) => {
        this.establishment = response.data;
      })
  }

  defaultAvatarTTSUrl = 'https://cdn.tictacsante.com/assets/images/doc/Default.png';//DEFAULT_PHOTO
  handleImageError(event: any) {
    event.target.src = this.defaultAvatarTTSUrl;
  }
}
