import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SearchEngineService} from "../../search-engine/search-engine.service";

@Component({
  selector: "app-list",
  templateUrl: "./list-establishment.component.html",
  styleUrls: ["./list-establishment.component.scss"],
})
export class ListEstablishmentComponent implements OnInit {
  firstPage: boolean = true;
  lastPage: boolean = false;
  loading: boolean = true;
  displayResult: number = 20;
  total: number = 0;
  countPages: number = 0;
  establishments: any;
  map: any;
  data = {
    speciality_reference: 1,
    city: "",
    country: "",
    reason_reference: -1,
    page: 0,
  };
  @ViewChild("mapElement", { static: true }) mapElement!: ElementRef;
  private reason: any;

  /**
   * Constructor
   * @param _searchEngineService
   * @param _route
   * @param _router
   */
  constructor(
    private _searchEngineService: SearchEngineService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
      this.data.country = params['country'];
      this.data.city = params['city'];
      this.data.speciality_reference = 1; //by default 1
      this.data.reason_reference = params['reason'];
      this.searchResult();
    })
  }

  searchResult() {
    this.loading = true;
    this._searchEngineService
      .searchResults(this.data)
      .subscribe(response => {
        this.loading = false;
        this.establishments = response?.data?.establishments;
        this.reason = response?.data?.reason;
        this._searchEngineService.specialitySubject.next(this.reason);
        if(this.data.page == 0){
          // this.total = response.data.result_map.total;
          this.calculTotalPage();
          this.lastPage = this.countPages <= this.data.page;
        }
      })
  }

  nextPage() {
    this.data.page++;
    this.firstPage = false;
    this.searchResult();
  }

  previousPage() {
    this.data.page--;
    if (this.data.page === 0) {
      this.firstPage = true;
    }
    if (this.data.page >= 0) {
      this.searchResult();
    }
  }

  calculTotalPage() {
    if (this.total > 0)
      this.countPages = Math.round(this.total / this.displayResult);
  }
  clicked = false
  clickedPracticien(practice: any) {
    this.clicked = !this.clicked
    // this._resultMapService.emitPractice(practice);
  }

  mouseEnterPractice(practice: any) {
    // this._resultMapService.emitPractice(practice);
  }

  mouseleave() {
    // if (!this.clicked)
    // this._resultMapService.emitZoom();
  }

  mouseEnterAnnuaire(annuaire: any) {
    // this._resultMapService.emitAnnuaire(annuaire);
  }

  defaultAvatarTTSUrl = "default.png"
  defaultSpeciality: string = 'Kinesiologue';
  handleImageError(event: any) {
    event.target.src = this.defaultAvatarTTSUrl;
  }

  onDetailPractice(annuaire:any) {
    //Todo Add url of establishment
    this._router.navigate(['/establishment', annuaire.reference]);
  }
}
