import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-establishment-profile",
  templateUrl: "./profile-card.component.html",
  styleUrls: ["./profile-card.component.scss"],
})
export class ProfileCardComponent implements OnInit {
  @Input() establishment!: any;

  constructor() {}

  gallery: any = [];
  ngOnInit(): void {
    this.establishment?.gallery?.forEach((element:any) => {
      if(element?.url){
        this.gallery.push(element?.url)
      }else{
        this.gallery.push('assets/images/cover/cover.webp');
      }
    });
  }
  defaultAvatarTTSUrl = 'https://cdn.tictacsante.com/assets/images/doc/Default.png';
  defaultCoverTTSUrl = 'https://cdn.tictacsante.com/assets/images/doc/covers/cover-tictacdoc.jpg';
  handleImageError(event: any, type: any) {
    if(type == 'cover')
      event.target.src = this.defaultCoverTTSUrl;
    else
      event.target.src = this.defaultAvatarTTSUrl;
  }
}
