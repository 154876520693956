import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homefive-banner',
    templateUrl: './homefive-banner.component.html',
    styleUrls: ['./homefive-banner.component.scss']
})
export class HomefiveBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}