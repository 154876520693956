import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cryptocurrency-invest-reasons',
    templateUrl: './cryptocurrency-invest-reasons.component.html',
    styleUrls: ['./cryptocurrency-invest-reasons.component.scss']
})
export class CryptocurrencyInvestReasonsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}