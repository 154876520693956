import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogPhoneValidationCodeComponent } from './dialog-phone-validation-code/dialog-phone-validation-code.component';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {environment} from "../../../../environments/environment";
import {MatRippleModule} from "@angular/material/core";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatButtonToggle, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {CommonModule} from "@angular/common";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {SecurityService} from "../security-service";
@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    NgxMatIntlTelInputComponent,
    MatDialogModule,
    MatRippleModule,
    MatButton,
    MatSlideToggle,
    MatIcon,
    MatInput,
    MatIconButton,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatFormFieldModule
  ],
  providers : [
    // SecurityService,
    /*{
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider
            ('239415642847-ui5ojj2mqe1s4n310vvgompi3v1sv73l.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('426812791556548')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }*/
  ]
})
export class InscriptionComponent implements OnInit {
  public validCode: boolean = false;
  public signUpForm!: FormGroup;
  public hide = true;
  successMessage: string = "";
  errorMessage: string = "";
  genre = "Mme";
  phone: any;
  isAcceptedTerms: boolean = false;
  establishmentRef: any;

  constructor(
    public _formbuilder: FormBuilder,
    private _route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    public _securityService: SecurityService,
    public _matdialog: MatDialog
    ) { }

  ngOnInit() {
    this._route.paramMap.subscribe((params) => {
      this.establishmentRef = params.get('establishmentRef');
    });
    this.signUpForm = this._formbuilder.group({
      genre: this.genre,
      lastname: this._formbuilder.control('', [Validators.required]),
      firstname: this._formbuilder.control('', [Validators.required]),
      email: this._formbuilder.control('', [Validators.required, Validators.email]),
      mobile: this._formbuilder.control(null, [Validators.required]),
      password: this._formbuilder.control('', [Validators.required]),
      cgu: this._formbuilder.control('', [Validators.required])
    });
  }

  validateInscriptionBooking() {
    this.errorMessage   = '';
    this.successMessage = '';
    if (this.signUpForm.valid) {
      const data = {
        "genre": this.signUpForm.value.genre,
        "lastname": this.signUpForm.value.lastname,
        "firstname": this.signUpForm.value.firstname,
        "email": this.signUpForm.value.email,
        "phone": this.signUpForm.value.mobile,
        "password": this.signUpForm.value.password,
        "prefix": this.signUpForm.value.mobile.dialCode,
        "establishment_reference": this.establishmentRef,
      };
      this._securityService.initInscription(data).subscribe((res) => {
        if(res.status === "OK"){
          const dialogRef = this._matdialog.open(DialogPhoneValidationCodeComponent, {
            disableClose: false,
            data: {
              title: 'Validation',
            },
            panelClass:"bg-white"
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
              if(result.validCode){
                this.successMessage = "Votre compte est créé avec succès";
                this.snackBar.open(this.successMessage, '×', {
                  verticalPosition: 'top',
                  duration: 5000,
                  panelClass: ['green-snackbar'],
                });
              }
            }
          });
          ///Todo
        }else if(res.status === "ERROR"){
          if(res?.message?.email_error === true){
            this.errorMessage = "L'email existe déjà";
          }else if (res?.message?.username_error === true){
            this.errorMessage = "Nom d'utilisateur existe déjà";
          }else{
            this.errorMessage = "L'email ou le mobile existe déjà";
          }
          this.snackBar.open(this.errorMessage, '×', {
            verticalPosition: 'top',
            duration: 5000,
            panelClass: ['red-snackbar'],
          });
        }
      });
    }
  }

  protected readonly environment = environment;
}
