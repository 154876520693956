import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {map} from "rxjs/operators";
import {EaHttpAbstract} from "../../../../@ea/abstract/http.abstract";

@Injectable({
  providedIn: 'root',
})
export class PlanningService {
  onSelectedTime: BehaviorSubject<any>;
  // Private
  rdvSubject: BehaviorSubject<any | null> = new BehaviorSubject(null);

  /**
   * Constructor
   *
   * @param _eaHttpAbstract
   */
  constructor(private _eaHttpAbstract: EaHttpAbstract) {
    this.onSelectedTime = new BehaviorSubject(null);
    this.rdvSubject = new BehaviorSubject([]);
  }
  /**
   * Add Call
   *
   * @param data
   * @returns {Observable<any>}
   */
  getTimesSlot(data: any): Observable<any> {
    const _data: any = {};
    _data.data_init = data.date_init;
    _data.establishment_reference = data.establishment_reference;
    _data.nbr_days = data.display_days;
    _data.reason_type_reference = data.reason_reference;
    return this._eaHttpAbstract.post("/public/establishments/planning", _data).pipe(
        map(response => {
          return response;
        })
    );
  }

  /**
   * Add Call
   *
   * @param data
   * @returns {Observable<any>}
   */
  startProcessRdv(data: any): Observable<any> {
    /**
     * Params
     */
    return this._eaHttpAbstract.post("/public/booking/start_process", data).pipe(
        map((response: any)=>{
          localStorage.setItem("token", response?.data?.token);
          return response;
        })
    );
  }

  confirmRdv(data: any): Observable<any>{
    const _data = {
      comment: data.commentaireRdv,
      patient_reference: data.idProche,
      token: localStorage.getItem("token")
    };
    return this._eaHttpAbstract.post("/public/booking/confirm_appointment", _data)
      .pipe(
        map((response: any)=>{
          if(response.status === "OK"){
            localStorage.removeItem('appointement');
          }
          return response;
        })
      );
  }

  getEstablishmentDetails(establishmentref: number) {
    const _data: any = {};
    _data.establishment_reference = establishmentref;
    return this._eaHttpAbstract.post("/public/establishments", _data).pipe(
      map(response=> {
        return response;
      })
    );
  }

  getPracticeDetails(practiceReference: string) {
    const _data: any = {};
    _data.reference = practiceReference;
    return this._eaHttpAbstract.post("/public/practices/detail", _data).pipe(
      map(response => {
        return response;
      })
    );
  }
}
