import { Component, OnInit } from '@angular/core';
import {ContactPageService} from "./contact-page.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  public contactForm!: FormGroup;
  constructor(private _contactPageService: ContactPageService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      fullName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }
  public saveContactUs(){
    if(this.contactForm.valid){
      this._contactPageService.saveContactUs(this.contactForm.value)
        .subscribe(value => {
          if(value.status === "OK"){
            //show success message
          }
        });
    }
  }
}
