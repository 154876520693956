import {Component, OnInit, HostListener, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  establishmentRef: any = -1;
  specialities: any[] = [];
    constructor(
        public router: Router,
        public _route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
      this.router.events.subscribe(() => {
        let activeRoute = this._route.root;
        while (activeRoute.firstChild) {
          activeRoute = activeRoute.firstChild;
        }
        activeRoute.paramMap.subscribe(params => {
          this.establishmentRef = params.get('establishmentRef');
        });
      });

      this.specialities = [
        {
          link: '/speciality-1',
          text: 'Speciality 1'
        },
        {
          link: '/speciality-2',
          text: 'Speciality 2'
        },
        {
          link: '/speciality-3',
          text: 'Speciality 3'
        },
        {
          link: '/speciality-4',
          text: 'Speciality 4'
        },
      ]
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    isSticky: boolean = false;
    isDropdownVisible: boolean = false;
    onMouseEnter() {
      this.isDropdownVisible = true;
    }
    onMouseLeave() {
      this.isDropdownVisible = false;
    }
    buttonClass = 'text-white hover:bg-red font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center';
    dropdownIconClass = 'w-2.5 h-2.5 ms-3';
    dropdownMenuClass = 'absolute dropdown-menu-connexion z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 text-left'; // Class for dropdown menu positioning and styling
    dropdownItemClass = 'block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white';

  dropdownItems = [
      { label: 'Connexion', link: '/sign-in' },
      { label: 'Créer un compte', link: '/sign-up' },
      // { label: 'Earnings', link: '#' },
      // { label: 'Sign out', link: '#' }
    ];
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

}
