import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../src/environments/environment";

/**
 * This will be removed in the upcoming versions
 */

@Injectable({ providedIn: 'root' })
export abstract class EaHttpAbstract {
  // env
  readonly #_url = environment.url_api;

  // injections
  #_http = inject(HttpClient);

  get<T> (endPoint: string, params: HttpParams = new HttpParams()): Observable<T> {
    return this.#_http.get<T>(`${this.#_getRestPrefix()}${endPoint}`, {
      headers: this._getHeaders(),
      params,
    });
  }

  post<T, D> (endPoint: string, data?: any): Observable<T> {
    return this.#_http.post<T>(
      `${this.#_getRestPrefix()}${endPoint}`,
      this._getParams(data),
      {
        withCredentials: true,
        responseType: "json",
      }
    );
  }

  put<T, D> (endPoint: string, data: any): Observable<T> {
    return this.#_http.put<T>(
      `${this.#_getRestPrefix()}${endPoint}`,
      this._getParams(data),
      {
        withCredentials: true,
        responseType: "json",
      }
    );
  }

  delete<T, D> (endPoint: string, data: D): Observable<T> {
    return this.#_http.delete<T>(`${this.#_getRestPrefix()}${endPoint}`, {
      body: this.convertToFormData(data),
    });
  }

  private _getHeaders (): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return new HttpHeaders(headersConfig);
  }

  private convertToFormData(data: any): FormData {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  }

  private _getParams<D extends Record<string, any>> (data: D): HttpParams {
    let params = new HttpParams();
    Object.keys(data).forEach(
      (key) => {
        if (data[key] !== null && data[key] !== undefined && typeof data[key] !== 'object' && /*data[key] !== "" && */data[key] !== " ") {
          params = params.append(`${key}`, `${data[key]}`);
        }
      }
    );
    return params;
  }

  #_getRestPrefix(): string {
    return this.#_url;
  }
}
