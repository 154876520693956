import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-platforms',
  templateUrl: './our-platforms.component.html',
  styleUrls: ['./our-platforms.component.scss']
})
export class OurPlatformsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
