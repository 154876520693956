import {Component, Input, OnInit} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';

@Component({
    selector: 'app-team-establishment',
    templateUrl: './team-establishment.component.html',
    styleUrls: ['./team-establishment.component.scss']
})
export class TeamEstablishmentComponent implements OnInit {
  practices!: any[];
    constructor(
        public router: Router
	) { }

    ngOnInit(): void {
      if(!this.practices){
        this.practices = [
          {
            picture: 'assets/img/cybersecurity-home/team/team-1.jpg',
            full_name: 'Larry Hofmann',
            specialty: 'Kinésithérapie orthopédique',
          },
          {
            picture: 'assets/img/cybersecurity-home/team/team-2.jpg',
            full_name: 'Gail Douthitt',
            specialty: 'Kinésithérapie neurologique',
          },
          {
            picture: 'assets/img/cybersecurity-home/team/team-3.jpg',
            full_name: 'Patricia Conlon',
            specialty: 'Kinésithérapie pédiatrique',
          },
          {
            picture: 'assets/img/cybersecurity-home/team/team-4.jpg',
            full_name: 'Danette Rushing',
            specialty: 'Kinésithérapie sportive',
          },
        ]
      }
    }

    teamSlides: OwlOptions = {
		nav: true,
		loop: true,
		margin: 30,
		center: true,
		dots: false,
		autoplay: true,
		autoplayHoverPause: true,
		navText: [
			"<i class='ri-arrow-left-s-line'></i>",
			"<i class='ri-arrow-right-s-line'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 3
			},
			768: {
				items: 3
			},
			992: {
				items: 5
			},
			1200: {
				items: 5
			}
		}
    }

  defaultAvatarTTSUrl = 'https://cdn.tictacsante.com/assets/images/doc/Default.png';//DEFAULT_PHOTO
  handleImageError(event: any) {
    event.target.src = this.defaultAvatarTTSUrl;
  }
}
