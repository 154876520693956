import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PlanningService} from "../../../../apps/planning-multipractices/planning.service";

@Component({
  selector: 'app-establishment-detail',
  templateUrl: './establishment-detail.component.html',
  styleUrls: ['./establishment-detail.component.scss']
})
export class EstablishmentDetailComponent implements OnInit {

  public establishment: any;
  private establishmentReference: number = -1;
  images: string[] = [
    "blessure.png",
    "chiropratique.png",
    "physiotherapie.png",
    "therapie-physique.png",
  ];
  showPhoneNumber: boolean = false;
  constructor(private _route: ActivatedRoute,
              private _planningService: PlanningService) { }

  ngOnInit(): void {
    let params = this._route.snapshot.params;
    this.establishmentReference = params['establishmentRef'];
    this._planningService.getEstablishmentDetails(this.establishmentReference)
      .subscribe((response: any) => {
        this.establishment = response.data;
      })
  }

  public getFullAddress(address: string, city: string, country: string, zip_code: string): string {
    let val = "";
    if (address) {
      val = address;
    }
    if (city) {
      if (val) {
        val += ", " + city;
      } else {
        val = city;
      }
    }
    if (country) {
      if (val) {
        val += ", " + country;
      } else {
        val = country;
      }
    }
    if (zip_code) {
      if (val) {
        val += ", " + zip_code;
      } else {
        val = zip_code;
      }
    }
    return val;
  }

  fullNumber: string = '';
  revealPhoneNumber() {
    this.fullNumber = this.establishment.phone;
    this.showPhoneNumber = true;
  }
}
