import {
  Component,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import {MapAdvancedMarker, MapInfoWindow} from "@angular/google-maps";

@Component({
  selector: 'app-hero-map',
  templateUrl: './hero-map.component.html',
  styleUrls: ['./hero-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeroMapComponent {

  options: google.maps.MapOptions = {
    mapId: "DEMO_MAP_ID",
    zoom: 13,
    scrollwheel: false,
    center: { lat: 34.00998105, lng: -6.83418274 },
    zoomControl: true,
    streetViewControl: false,
    scaleControl: true,
    mapTypeControl: false,
    styles: [
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'water',
        stylers: [
          {
            color: '#cfd9e0'
          }
        ]
      }
    ]
  };
  rabatLocations: any[] = [
    { lat: 34.0279, lng: -6.8365, title: "Kasbah of the Udayas" },
    { lat: 34.0231, lng: -6.8228, title: "Hassan Tower" },
    { lat: 34.0229, lng: -6.8229, title: "Mausoleum of Mohammed V" },
    { lat: 34.0149, lng: -6.8370, title: "Rabat Archaeological Museum" },
    { lat: 34.0039, lng: -6.8207, title: "Chellah Necropolis" },
    { lat: 34.0129, lng: -6.8346, title: "Royal Palace of Rabat" },
    { lat: 34.0278, lng: -6.8363, title: "Andalusian Gardens" },
    { lat: 34.0209, lng: -6.8417, title: "Rabat Medina (Old Town)" },
    { lat: 34.0177, lng: -6.8376, title: "St. Peter's Cathedral" },
    { lat: 34.0161, lng: -6.8307, title: "Mohammed VI Museum of Modern and Contemporary Art" }
  ];
  selectedLocation: any;

  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  onMarkerClick(marker: MapAdvancedMarker, location: any) {
    this.selectedLocation = location;
    this.infoWindow.open(marker);
  }
  protected readonly location = location;
  hideTitle: boolean = false;
  topPosition = 400;
  showHeroMap: boolean = false;
  toggleHeroMap() {
    this.showHeroMap = !this.showHeroMap;
    if(this.showHeroMap){
      this.topPosition = window.innerHeight - 140;
      document.documentElement.style.setProperty('--map-height', `${window.innerHeight - 140}px`);
      this.hideTitle = true;
      //744
    } else {
      this.topPosition = 400;
      this.hideTitle = false;
      document.documentElement.style.setProperty('--map-height', `400px`);
    }
  }
  trackLocation(index: number, location: any): any {
    return location ? location.id : undefined; // Use unique property like `id` to track
  }
}
