import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LocalStorageService} from './storage.service';
import {BehaviorSubject, Observable} from 'rxjs';
export interface MyGlobal {}
export abstract class GlobalRef {
  abstract get nativeGlobal(): MyGlobal;
}

export class BrowserGlobalRef extends GlobalRef {
  get nativeGlobal(): MyGlobal {
    return window as MyGlobal;
  }
}
@Injectable()
export class AutoCompleteSearchService {
  onLocationDataChanged: BehaviorSubject<any>;
  onSpecialityDataChanged: BehaviorSubject<any>;

  constructor(private _http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object,
              private _global: GlobalRef, private _localStorageService: LocalStorageService) {
    this.onLocationDataChanged = new BehaviorSubject([]);
    this.onSpecialityDataChanged = new BehaviorSubject([]);
  }

  changeLocation(Data: any): void {
    this.onLocationDataChanged.next(Data);
  }

  changeSpeciality(Data: any): void {
    this.onSpecialityDataChanged.next(Data);
  }

  getPredictions(url: string, query: string): Promise<any> {
    return new Promise(resolve => {
      this._http.get(url, {params: new HttpParams().set('query', query)})
        .subscribe((data: any) => {
          if (data) {
            resolve(data);
          } else {
            resolve(false);
          }
        });
    });
  }

  getLatLngDetail(url: string, lat: string, lng: string): Promise<any> {
    return new Promise(resolve => {
      this._http.get(url, {params: new HttpParams().set('lat', lat).set('lng', lng)})
        .subscribe((data: any) => {
          if (data) {
            resolve(data);
          } else {
            resolve(false);
          }
        });
    });
  }

  getPlaceDetails(url: string, placeId: string): Promise<any> {
    return new Promise(resolve => {
      this._http.get(url, {params: new HttpParams().set('query', placeId)})
        .subscribe((data: any) => {
          if (data) {
            resolve(data);
          } else {
            resolve(false);
          }
        });
    });
  }

  getGeoCurrentLocation(): Promise<any> {
    return new Promise(resolve => {
      if (isPlatformBrowser(this.platformId)) {
        const _window: any = this._global.nativeGlobal;
        if (_window.navigator.geolocation) {
          _window.navigator.geolocation.getCurrentPosition((pos: any) => {
            const latlng: any = {lat: parseFloat(pos.coords.latitude + ''), lng: parseFloat(pos.coords.longitude + '')};
            resolve(latlng);
          }, (error: any) => {
            resolve(false);
          });
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  }

  getGeoLatLngDetail(latlng: any): Observable<any> {
    return new Observable(observer => {
      if (isPlatformBrowser(this.platformId)) {
        const _window: any = this._global.nativeGlobal;
        const geocoder: any = new _window.google.maps.Geocoder();

        geocoder.geocode({ 'location': latlng }, (results: any, status: any) => {
          if (status === 'OK' && results[0]) {
            this.getGeoPlaceDetail(results[0].place_id).subscribe(
              (result) => {
                if (result) {
                  observer.next(result);
                } else {
                  observer.next(false);
                }
                observer.complete();
              },
              (error) => {
                observer.error(error); // Handle any potential errors from getGeoPlaceDetail
              }
            );
          } else {
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  getGeoPrediction(params: any): Observable<any> {
    return new Observable(observer => {
      if (isPlatformBrowser(this.platformId)) {
        const _window: any = this._global.nativeGlobal;
        const placesService: any = new _window.google.maps.places.AutocompleteService();
        let queryInput: any = {};
        const promiseArr: any = [];

        // Construct query input based on parameters
        if (params.countryRestriction.length) {
          queryInput = {
            input: params.query,
            componentRestrictions: { country: params.countryRestriction }
          };
        } else {
          queryInput = { input: params.query };
        }

        // Add geolocation data if available
        if (params.geoLocation) {
          queryInput.location = new _window.google.maps.LatLng(
            parseFloat(params.geoLocation[0]),
            parseFloat(params.geoLocation[1])
          );
          queryInput.radius = params.radius;
        }

        // Prepare promise array based on geo types
        if (params.geoTypes.length) {
          for (let i = 0; i < params.geoTypes.length; i++) {
            const _tempQuery: any = { ...queryInput }; // Clone queryInput to avoid mutation
            _tempQuery.types = [params.geoTypes[i]];
            promiseArr.push(this.geoPredictionCall(placesService, _tempQuery));
          }
        } else {
          promiseArr.push(this.geoPredictionCall(placesService, queryInput));
        }

        // Use Promise.all to handle all predictions
        Promise.all(promiseArr).then(values => {
          const val: any = values;
          if (val.length > 1) {
            let _tempArr: any = [];
            for (let j: number = 0; j < val.length; j++) {
              if (val[j] && val[j].length) {
                _tempArr = _tempArr.concat(val[j]);
              }
            }
            _tempArr = this.getUniqueResults(_tempArr);
            observer.next(_tempArr);
          } else {
            observer.next(values[0]);
          }
          observer.complete(); // Complete the observable after emitting the result
        }).catch(error => {
          observer.error(error); // Handle any errors from Promise.all
        });
      } else {
        observer.next(false);
        observer.complete(); // Complete observable for non-browser environments
      }
    });
  }

  getGeoPlaceDetail(placeId: string): Observable<any> {
    return new Observable(observer => {
      if (isPlatformBrowser(this.platformId)) {
        const _window: any = this._global.nativeGlobal;
        if(_window.google?.maps.places){
          const placesService: any = new _window.google.maps.places.PlacesService(document.createElement('div'));

          placesService.getDetails({
            placeId: placeId,
            fields: ['geometry', 'formatted_address', 'address_components', 'reference', 'place_id', 'name']
          }, (result: any, status: any) => {
            if (status === 'OK') {
              observer.next(result);
              observer.complete();
            } else {
              this.getGeoPaceDetailByReferance(result.referance).subscribe((referanceData: any) => {
                if (!referanceData) {
                  observer.next(false);
                } else {
                  observer.next(referanceData);
                }
                observer.complete();
              }, (error) => {
                observer.error(error);
              });
            }
          });
        }
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  getGeoPaceDetailByReferance(referance: string): Observable<any> {
    return new Observable(observer => {
      if (isPlatformBrowser(this.platformId)) {
        const _window: any = this._global.nativeGlobal;
        const placesService: any = new _window.google.maps.places.PlacesService();

        placesService.getDetails({ 'reference': referance }, (result: any, status: any) => {
          if (status === _window.google.maps.places.PlacesServiceStatus.OK) {
            observer.next(result);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  addRecentList(localStorageName: string, result: any, itemSavedLength: number): any {
    this.getRecentList(localStorageName).then((data: any) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].description === result.description) {
            data.splice(i, 1);
            break;
          }
        }
        data.unshift(result);
        if (data.length > itemSavedLength) {
          data.pop();
        }
        this._localStorageService.setItem(localStorageName, JSON.stringify(data));
      }
    });
  }

  getRecentList(localStorageName: string): Promise<any> {
    return new Promise(resolve => {
      let value: any = this._localStorageService.getItem(localStorageName);
      if (value) {
        value = JSON.parse(value);
      } else {
        value = [];
      }
      resolve(value);
    });
  }

  private getUniqueResults(arr: any): any {
    return Array.from(arr.reduce((m: any, t: any) => m.set(t.place_id, t), new Map()).values());
  }

  private geoPredictionCall(placesService: any, queryInput: any): Promise<any> {
    const _window: any = this._global.nativeGlobal;
    return new Promise(resolve => {
      placesService.getPlacePredictions(queryInput, (result: any, status: any) => {
        if (status === _window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          resolve(false);
        }
      });
    });
  }
}
